import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

import {
	resetBasketState,
	setActivePaymentOption,
	setActiveServiceType,
	setActiveTableNumber,
	setPosOrderId,
} from 'modules/basket/basket.slice';
import { RootState } from 'modules/core/state/root.reducer';
import { resetOrderState } from 'modules/order/order.slice';
import { resetPayGoState } from 'modules/pay-go/pay-go.slice';

const useOnpl = (currentVenueId?: string) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const navigate = useNavigate();
	const { activeBasket, venue, posOrderId: basketPosOrderId } = useSelector(
		(state: RootState) => state.basket,
	);
	const { activeVenue } = useSelector((state: RootState) => state.venue);
	const { activeOrder } = useSelector((state: RootState) => state.checkout);
	const { activeOrder: payGoActiveOrder, orders } = useSelector(
		(state: RootState) => state.payGo.activeSession,
	);

	const isOnpl = useMemo(() => {
		// Check if the venue stored in the venue's state has onpl enabled
		if (activeVenue?.services?.tableService?.onplEnabled) {
			if (!currentVenueId || activeVenue.id === currentVenueId) {
				return true;
			}
		}

		// Check if the venue stored as part of the basket's state has onpl enabled
		if (venue?.services?.tableService?.onplEnabled) {
			if (!currentVenueId || venue.id === currentVenueId) {
				return true;
			}
		}

		// Check if the venue stored in the order's state has onpl enabled
		if (activeOrder?.venue?.services?.tableService?.onplEnabled) {
			if (!currentVenueId || activeOrder.venue.id === currentVenueId) {
				return true;
			}
		}

		return false;
	}, [activeBasket, activeVenue, currentVenueId]);

	const isGroupOrder = Boolean(payGoActiveOrder?.isGroupOrder);

	const shouldShowGroupOrderBanner = useMemo(() => {
		return (
			Boolean(payGoActiveOrder?.isGroupOrder) ||
			(!basketPosOrderId && orders.length > 0)
		);
	}, [activeBasket, activeVenue]);

	const navigateBack = async (
		venueId: string,
		tableNumber?: string,
		posId?: string,
		keepBasket?: boolean,
		keepPayGoState?: boolean,
		resetOrder?: boolean,
		backToBill?: boolean,
	) => {
		await dispatch(resetOrderState());

		if (!keepBasket) {
			await dispatch(resetBasketState());
		}

		if (!keepPayGoState) {
			await dispatch(resetPayGoState());
		}

		if (backToBill) {
			history.push(`/venue/${venueId}/pay-go/order/${posId}`);
			return;
		}

		if (posId) {
			await dispatch(setActiveServiceType('tableService'));
			tableNumber && (await dispatch(setActiveTableNumber(tableNumber)));
			await dispatch(setActivePaymentOption('OrderAndPay'));
			if (resetOrder) {
				history.push(`/menu/${venueId}`);
			} else {
				if (basketPosOrderId) {
					await dispatch(setPosOrderId(posId));
				}
				history.push(`/menu/${venueId}/order/${posId}`);
			}
		} else {
			await dispatch(resetPayGoState());
			await dispatch(resetOrderState());

			if (!keepBasket) {
				await dispatch(resetBasketState());
			}
			navigate(`/menu/${venueId}`);
		}
	};

	return { isOnpl, isGroupOrder, shouldShowGroupOrderBanner, navigateBack };
};

export default useOnpl;
